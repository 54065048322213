import React from 'react'

const Appt = () => {
    return (
        <div className='apptcard'>

            <iframe className="appt" src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3n3uFSXa_1SMxcYXLRGhrRsdL1wG3OFkdlTe3nSCcUcvFTma4rMNzAvHq_zGiCeV0cSTFo3uAE?gv=true" border="0" width="100%" height="700" frameborder="0"></iframe>

        </div>
    )
}

export default Appt
